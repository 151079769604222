import styled from "styled-components";

export const Wrapper = styled.div`
  background: #69BAC1;
  width: 100%;
  padding: 60px 0 48px 0;
`;

export const Inner = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const LinksSocial =styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid #FFFFFF;
  @media screen and (max-width: 900px) {
    position: relative;
  }
`;

export const LinksPages =styled.div`
  a {
    color: #fff;
    text-decoration: none;
    font-weight: 900;
    font-size: 17px;
    margin-right: 24px;
    @media screen and (max-width: 900px) {
      margin-right: 0;
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
    }
  }
`;
export const Social =styled.div`
  display: flex;
  .vk-footer {
    margin-right: 8px;
  }
  @media screen and (max-width: 900px) {
    position: absolute;
    top: 0;
    right: 0;
  }
  a {
    display: inline-block;
    width: 32px;
    height: 32px;
    img:hover {
      cursor: pointer;
    }
  }
`;

export const ContactsDetails = styled.div`
  display: flex;
  justify-content: space-between;
  color: #fff;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #FFFFFF;
  @media screen and (max-width: 900px) {
    display: flex;
    justify-content: inherit;
    flex-direction: column;
  }
`;
export const Address = styled.div`
  max-width: 334px;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  .firstLineOffice {
    padding-bottom: 12px;
  }
`;
export const Phones = styled.div`
  line-height: 24px;
  a {
    color: #fff;
    font-weight: 900;
    font-size: 17px;
  }
  span{
    transform: translateY(-7px);
    display: inline-block;
    text-align: center;
    width: 96%;
    @media screen and (max-width: 900px) {
      text-align: left;
    }
  }
`;
export const Emails = styled.div`
  a {
    color: #fff;
  }
`;

export const FactureData = styled.div``;
export const CopyRight = styled.div`
  color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  padding: 20px 0 16px 0;
`;
export const WrapData = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #FFFFFF;
  ul li {
    list-style: none;
    display: inline-block;
    margin-right: 24px;
    padding-top: 20px;
    @media screen and (max-width: 900px) {
      padding-top: inherit;
    }
  }
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
`;

export const DataOne = styled.div`
  max-width: 545px;
  @media screen and (max-width: 900px) {
    max-width: inherit;
  }
`;
export const DataTwo = styled.div`
  max-width: 259px;
  @media screen and (max-width: 900px) {
    max-width: inherit;
  }
`;
export const DataThree = styled.div``;

export const BottomText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #fff;
  opacity: 0.7;
  padding-top: 24px;
  max-width: 860px;
     a {
         color :#fff;
     }
`;
