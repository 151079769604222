import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Link, Navigate
import { createGlobalStyle } from "styled-components";
import { ScrollToTop } from "./helpers/scrollTop";
import { Menu } from "./components/menu";
import { Main } from "./pages/Main";
import { About } from "./pages/About";
import { Projects } from "./pages/Projects";
import { Steps } from "./pages/Steps";
import { News } from "./pages/News";
import { SMI } from "./pages/SMI";
import { Volunteers } from "./pages/Volunteers";
import { Reports } from "./pages/Reports";
import { Partners } from "./pages/Partners";
import { Contacts } from "./pages/Contacts";
import { NeedHelp } from "./pages/NeedHelp";
import { Footer } from "./components/footer";
import { VideoYoutube } from "./components/smiContent/VideoYoutube";
import { VideoYoutubeTwo } from "./components/smiContent/VideoYoutubeTwo";
import { VideoYoutubeThree } from "./components/smiContent/VideoYoutubeThree";
import { StudenikinaPage } from "./pages/Studenikina";
import { NewsViewItemPage } from "./pages/NewsViewItemPage";
import { Iurist } from "./components/aboutUs/people/iurist";
import { ZamDir } from "./components/aboutUs/people/zamDir";
import { SocialWorker } from "./components/aboutUs/people/socWork";
import { Psyholog } from "./components/aboutUs/people/psyholog";
import { Buhgalter } from "./components/aboutUs/people/buhgalter";
import { SocialSpecialist } from "./components/aboutUs/people/podopechnie";
import { Cgp } from "./pages/Cgp";
import { Priut } from "./pages/Priut";
import { MetodPomoshi } from "./pages/MetodPomoshi";
import { Napravlenia } from "./pages/Napravlenia";
import { ConsulitatsionniiTsentr } from "./pages/ConsulitatsionniiTsentr";
import { CgpStraniPage } from "./pages/CgpStraniPage";
import { NotFoundPage } from "./pages/NotFound";
import { FirstRoomPage } from "./pages/FirstRoomPage";
import { SecondRoom } from "./components/remont/secondRoom";
import { ThirdRoom } from "./components/remont/thirdRoom";
import { FourRoom } from "./components/remont/fourRoom";
import { FiveRoom } from "./components/remont/fiveRoom";
import { SixRoom } from "./components/remont/sixRoom";
import { SevenRoom } from "./components/remont/sevenRoom";
import { EightRoom } from "./components/remont/eightRoom";
import { NineRoom } from "./components/remont/nineRoom";
import { TenRoom } from "./components/remont/tenRoom";
import { ElevenRoom } from "./components/remont/elevenRoom";
import { TwelveRoom } from "./components/remont/twelveRoom";
import { ThirteenRoom } from "./components/remont/thirteenRoom";
import { FourTeenRoom } from "./components/remont/fourTeenRoom";
import { FifTeenRoom } from "./components/remont/fifTeenRoom";
import { SixTeenRoom } from "./components/remont/sixTeenRoom";
import { SevenTeenRoom } from "./components/remont/sevenTeenRoom";
import { NineTeenRoom } from "./components/remont/nineTeenRoom";
import { CentriPage } from "./pages/CentriPage";
import { AnketaPage } from "./pages/AnketaPage";
import { Agree } from "./components/agree/intex";
import {Privacy} from "./components/privacy";

const GlobalStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    button:focus {outline:0;}
    font-family: 'Mulish', sans-serif;
  }
  input[type='number'] {
    -moz-appearance:textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
    a {
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }
  }
  .showDesktop {
    display: block;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  .showMobile {
    display: none;
    @media screen and (max-width: 900px) {
      display: block;
    }
  }
`;

export const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <GlobalStyle />

      <Menu />

      <Routes>
        <Route
          path="/"
          element={
            <>
              <Main />
            </>
          }
        />
        <Route
          path="/onas"
          element={
            <>
              <About />
            </>
          }
        />
        <Route
          path="/proiecti"
          element={
            <>
              <Projects />
            </>
          }
        />
        <Route
          path="/etapi"
          element={
            <>
              <Steps />
            </>
          }
        />
        <Route
          path="/novosti"
          element={
            <>
              <News />
            </>
          }
        />
        <Route
          path="/mivsmi"
          element={
            <>
              <SMI />
            </>
          }
        />

        <Route
          path="/video/1"
          element={
            <>
              <VideoYoutube />
            </>
          }
        />

        <Route
          path="/video/2"
          element={
            <>
              <VideoYoutubeTwo />
            </>
          }
        />

        <Route
          path="/video/3"
          element={
            <>
              <VideoYoutubeThree />
            </>
          }
        />

        <Route
          path="/volonteri"
          element={
            <>
              <Volunteers />
            </>
          }
        />
        <Route
          path="/otcheti"
          element={
            <>
              <Reports />
            </>
          }
        />

        <Route
          path="/partneri"
          element={
            <>
              <Partners />
            </>
          }
        />
        <Route
          path="/contacti"
          element={
            <>
              <Contacts />
            </>
          }
        />
        <Route
          path="/nugnapomosi"
          element={
            <>
              <NeedHelp />
            </>
          }
        />
        <Route
          path="/studenikina"
          element={
            <>
              <StudenikinaPage />
            </>
          }
        />

        <Route
          path="/novosti/:id"
          element={
            <>
              <NewsViewItemPage />
            </>
          }
        />

        <Route
          path="/about/zam-directora"
          element={
            <>
              <ZamDir />
            </>
          }
        />

        <Route
          path="/about/specialist-po-sotsialinoi-rabote"
          element={
            <>
              <SocialWorker />
            </>
          }
        />

        <Route
          path="/about/psyholog"
          element={
            <>
              <Psyholog />
            </>
          }
        />

        <Route
          path="/about/iurist"
          element={
            <>
              <Iurist />
            </>
          }
        />

        <Route
          path="/about/buhgalter"
          element={
            <>
              <Buhgalter />
            </>
          }
        />

        <Route
          path="/about/spetstialist-po-sotsialinoi-rabote"
          element={
            <>
              <SocialSpecialist />
            </>
          }
        />

        <Route
          path="/cgp"
          element={
            <>
              <Cgp />
            </>
          }
        />

        <Route
          path="/priut"
          element={
            <>
              <Priut />
            </>
          }
        />

        <Route
          path="/metodicheskaia-pomoshi"
          element={
            <>
              <MetodPomoshi />
            </>
          }
        />

        <Route
          path="/napravlenia-deeatelinosti"
          element={
            <>
              <Napravlenia />
            </>
          }
        />

        <Route
          path="/consulitationnii-tsentr"
          element={
            <>
              <ConsulitatsionniiTsentr />
            </>
          }
        />

        <Route
          path="/krizisnye-centry"
          element={
            <>
              <CentriPage />
            </>
          }
        />

        <Route
          path="/centri"
          element={
            <>
              <CgpStraniPage />
            </>
          }
        />

        <Route
          path="/remont/01"
          element={
            <>
              <FirstRoomPage />
            </>
          }
        />
        <Route
          path="/remont/02"
          element={
            <>
              <SecondRoom />
            </>
          }
        />
        <Route
          path="/remont/03"
          element={
            <>
              <ThirdRoom />
            </>
          }
        />
        <Route
          path="/remont/04"
          element={
            <>
              <FourRoom />
            </>
          }
        />
        <Route
          path="/remont/05"
          element={
            <>
              <FiveRoom />
            </>
          }
        />
        <Route
          path="/remont/05"
          element={
            <>
              <FiveRoom />
            </>
          }
        />
        <Route
          path="/remont/06"
          element={
            <>
              <SixRoom />
            </>
          }
        />
        <Route
          path="/remont/07"
          element={
            <>
              <SevenRoom />
            </>
          }
        />
        <Route
          path="/remont/08"
          element={
            <>
              <EightRoom />
            </>
          }
        />
        <Route
          path="/remont/09"
          element={
            <>
              <NineRoom />
            </>
          }
        />
        <Route
          path="/remont/10"
          element={
            <>
              <TenRoom />
            </>
          }
        />
        <Route
          path="/remont/11"
          element={
            <>
              <ElevenRoom />
            </>
          }
        />
        <Route
          path="/remont/12"
          element={
            <>
              <TwelveRoom />
            </>
          }
        />
        <Route
          path="/remont/13"
          element={
            <>
              <ThirteenRoom />
            </>
          }
        />
        <Route
          path="/remont/14"
          element={
            <>
              <FourTeenRoom />
            </>
          }
        />
        <Route
          path="/remont/15"
          element={
            <>
              <FifTeenRoom />
            </>
          }
        />
        <Route
          path="/remont/16"
          element={
            <>
              <SixTeenRoom />
            </>
          }
        />
        <Route
          path="/remont/17"
          element={
            <>
              <SevenTeenRoom />
            </>
          }
        />
        <Route
          path="/remont/18"
          element={
            <>
              <NineTeenRoom />
            </>
          }
        />

        <Route
          path="/volonteri/anketa"
          element={
            <>
              <AnketaPage />
            </>
          }
        />
        <Route
          path="/agree"
          element={
            <>
              <Agree />
            </>
          }
        />
      <Route
          path="/privacy"
          element={
              <>
                  <Privacy />
              </>
          }
      />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </Router>
  );
};
